import React from 'react'

import { insatssummaLinkText, omstallningsinsatsLink } from './Ekonomi.helpers'

import { Box, Grid, Link } from '@mui/material'
import useGetArende from './hooks/useGetArende'
import { ChipLinkGroup } from '@local/src/components/FilterBar/ChipLinkGroup'

const EkonomiAsideLinks = () => {
    const arende = useGetArende()
    const klientId = arende?.klient?.id

    return (
        <Box mb={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid container mb={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item>
                    <ChipLinkGroup label="Insatser">
                        <Link href={omstallningsinsatsLink(klientId)} rel="noopener noreferrer" target="_blank">
                            Skapa omställningsinsats
                        </Link>

                        <Link href={insatssummaLinkText(klientId)} rel="noopener noreferrer" target="_blank">
                            Total insatssumma för omställningskunden
                        </Link>
                    </ChipLinkGroup>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EkonomiAsideLinks
