import React from 'react'

import EkonomiTab from './components/EkonomiTab'
import { ekonomiContent } from './Ekonomi.helpers'

import { Alert, Box, Grid, Tabs } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import useGetArende from './hooks/useGetArende'
import useGetUtbetalningar from './hooks/useGetUtbetalningar'
import useIsLoadingArende from './hooks/useIsLoadingArende'
import useIsErrorGetArende from './hooks/useIsErrorGetArende'
import { Loader } from '@local/src/components/Loader'
import StyledTab from './StyledTab'
import AgeHandlaggarMeddelande from './AgeHandlaggarMeddelande'
import { isOmstallningsArende } from '@local/src/utils/arendeHelpers'
import EkonomiAside from './EkonomiAside'

const Ekonomi = () => {
    const arende = useGetArende()
    const isLoading = useIsLoadingArende()
    const isError = useIsErrorGetArende()
    const utbetalningar = useGetUtbetalningar()

    const isOmstallningArende = isOmstallningsArende(arende?.arendeTyp)
    const [value, setValue] = React.useState(ekonomiContent.tabsKeys.alla)

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }

    if (isLoading) return <Loader />

    return (
        <Box py={4} data-testid="ekonomi-tabs">
            {isError && <Alert severity="error">Det gick inte att hämta ärendet</Alert>}
            <AgeHandlaggarMeddelande />

            <Grid container spacing={5}>
                <Grid item md={8} xs={12} order={{ md: 1, xs: 2 }}>
                    <TabContext value={value}>
                        {Boolean(utbetalningar?.length) && (
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ hidden: true }}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <StyledTab label={ekonomiContent.tabsKeys.alla} currentLabel={value} setValue={setValue} />
                                <StyledTab label={ekonomiContent.tabsKeys.insatserStudiestod} currentLabel={value} setValue={setValue} />

                                {isOmstallningArende && (
                                    <StyledTab label={ekonomiContent.tabsKeys.age} currentLabel={value} setValue={setValue} />
                                )}
                            </Tabs>
                        )}

                        <TabPanel value={ekonomiContent.tabsKeys.alla} sx={{ padding: 1 }}>
                            <EkonomiTab utbetalningsbeslut={utbetalningar} tabKey={ekonomiContent.tabsKeys.alla} />
                        </TabPanel>
                        <TabPanel value={ekonomiContent.tabsKeys.insatserStudiestod} sx={{ padding: 1 }}>
                            <EkonomiTab utbetalningsbeslut={utbetalningar} tabKey={ekonomiContent.tabsKeys.insatserStudiestod} />
                        </TabPanel>
                        <TabPanel value={ekonomiContent.tabsKeys.age} sx={{ padding: 1 }}>
                            <EkonomiTab utbetalningsbeslut={utbetalningar} tabKey={ekonomiContent.tabsKeys.age} />
                        </TabPanel>
                    </TabContext>
                </Grid>

                <Grid item md={4} xs={12} order={{ md: 2, xs: 1 }}>
                    <EkonomiAside />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Ekonomi
